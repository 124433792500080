import * as React from "react"
import Page from '../components/page/Page';
import '../styles/styles.scss';

const Styleguide = () => {
  return (
    <Page>
      <main className="sds-content-wrapper" style={{ flex: '1' }} >
        <h1>Skralan.be Styleguide</h1>
        <h6>A list of elements commonly used on the website.</h6>
        <hr />

        <section>
          <h2>COLORS</h2>

          <ul>
            <li>RGBA, hex</li>
          </ul>
        </section>

        <section>
          <h2>HEADINGS</h2>


          <h1>H1. This is a Page Title.</h1>
          <h2>H2. This is a header.</h2>
          <h3>H3. This is a medium header.</h3>
          <h4>H4. This is a sidebar header.</h4>
          <h5>H5. This is a small header.</h5>
          <h6>H6. This is a tiny header.</h6>
          <p className="sds-margin-block-end--base">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque in pellentesque nulla. Quisque egestas vehicula felis, in semper libero hendrerit at. Sed maximus ornare ligula, vitae consectetur diam fringilla at.</p>
          <p className="sds-margin-block-end--base">
            Vestibulum id vestibulum est. Morbi rutrum quis lacus vel hendrerit. Morbi massa neque, porttitor non porttitor vel, maximus et urna. Nulla sit amet libero velit. Quisque nec est tincidunt, sodales nibh vitae, mollis felis. Suspendisse malesuada diam nec tortor mollis dapibus. Nunc vulputate efficitur tortor.
          </p>
        </section>

        <div class="sectionHeading">
          <span class="sectionTitle">INLINE ELEMENTS</span>
          <div class="sectionSeperator"></div>
        </div>

        <div id="inlineElements">
          <p><a href="#">This is a text link</a></p>
          <p><strong>Strong is used to indicate strong importance</strong></p>

          <p>The <b>b element</b> is stylistically different text from normal text, without any special importance</p>

          <p>The <em>i element</em> is text that is set off from the normal text</p>

          <p>The <u>u element</u> is text with an unarticulated, though explicitly rendered, non-textual annotation</p>

          <p><small>This small text is small for for fine print, etc.</small></p>

          <p><blockquote>Vestibulum id vestibulum est. Morbi rutrum quis lacus vel hendrerit. Morbi massa neque, porttitor non porttitor vel, maximus et urna.</blockquote></p>
          <p><q cite="https://developer.mozilla.org/en-US/docs/HTML/Element/q">This text is a short inline quotation</q></p>

          <p><cite>This is a citation</cite></p>
        </div>

        <div class="sectionHeading">
          <span class="sectionTitle">FORM ELEMENTS</span>
          <div class="sectionSeperator"></div>
        </div>

        <div id="formElements">
          <form>
            <div class="form-item">
              <input type="text" placeholder="text field" name="" id="" />
            </div>
            <div class="form-item">
              <input type="text" required="required" placeholder="required text field" name="" id="" />
            </div>
            <div class="form-item">
              <textarea name="" id="" cols="40" rows="5"></textarea>
            </div>

            <div class="form-item">
              <select name="" id="">
                <option value="default">-- Select --</option>
                <option value="">Only</option>
                <option value="">One</option>
                <option value="">Item</option>
              </select>
            </div>

            <div class="form-item">
              <select multiple name="" id="">
                <option value="">Select</option>
                <option value="">Multiple</option>
                <option value="">Items</option>
              </select>
            </div>

            <div class="form-item form-type-radios">
              <div class="form-item"><input type="radio" name="example-radios" id="" /><label for="" class="option">Radio One</label></div>
              <div class="form-item"><input type="radio" name="example-radios" id="" /><label for="" class="option">Radio Two</label></div>
              <div class="form-item"><input type="radio" name="example-radios" id="" /><label for="" class="option">Radio Three</label></div>
              <div class="form-item"><input type="radio" name="example-radios" id="" /><label for="" class="option">Radio Four</label></div>
            </div>

            <div class="form-item form-type-checkboxes">
              <div class="form-item"><input type="checkbox" name="" id="" /><label for="">checkbox 1</label></div>
              <div class="form-item"><input type="checkbox" name="" id="" /><label for="">checkbox 2</label></div>
              <div class="form-item"><input type="checkbox" name="" id="" /><label for="">checkbox 3</label></div>
              <div class="form-item"><input type="checkbox" name="" id="" /><label for="">checkbox 4</label></div>
            </div>

            <div class="form-actions">
              <input type="submit" class="form-submit" />
              <input type="button" value="Cancel" class="form-submit" />
            </div>
          </form>
        </div>

      </main>
    </Page>
  )
}

export default Styleguide;

export const Head = () => <title>Skralan 🎸🎵 - Styleguide</title>
