import React from 'react';
import classnames from 'classnames';
import { Link } from "gatsby"
import Logo from '../logo/Logo';
import './menu.scss';

const Menu = props => {
  const menuClasses = classnames('sds-main-menu');

  const activeClassname = "active";

  return (
    <section className={menuClasses}>
      <Logo />
      <nav className="sds-nav-wrapper">
        {/* <Link to="/" activeClassName={activeClassname} className="sds-nav-link internal">Home</Link>*/}
        {/* <Link to="/" activeClassName={activeClassname} className="sds-nav-link internal">Audio/Video</Link> */}
        {/*<h6><Link to="/shows" activeClassName={activeClassname} className="sds-nav-link sds-link-hover--2 internal">Live Shows</Link></h6>*/}
        {/* <Link to="/" activeClassName={activeClassname} className="sds-nav-link internal">Contact & Booking</Link> */}
        <h6><Link to="https://instagram.com/skralanofficial" activeClassName={activeClassname} className="sds-nav-link sds-link-hover--3 social-media external instagram" target={"_blank"} rel="noreferrer">Instagram</Link></h6>
      </nav>
    </section>
  )
}

Menu.propTypes = {}

export default Menu
