import * as React from "react"
import classnames from 'classnames';
import Menu from '../menu/Menu';
import Footer from '../footer/Footer';
import './page.scss'

const Page = ({ children, className }) => {
  const pageClasses = classnames("sds-page--wrapper", className);

  return (
    <div className={pageClasses}>
      <Menu />
      <main className="sds-page--content">
        {children}
      </main>
      <Footer />
    </div >
  )
}

export default Page;
