import React from 'react'
import './footer.scss';

const Footer = () => {
  return (
    <footer className="sds-footer">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 192"><path d="M0,0L80,16c80,16,240,48,400,74.7,160,26.3,320,48.3,480,48,160,.3,320-21.7,400-32l80-10.7v96H0V0Z" fill="#f7fafd" /></svg>
      <div className='sds-footer--content'>
        <div className="sds-content-wrapper">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <small className='sds-color--th-text-secondary'>Website by <a href="https://www.spacecowboy.be" target="_blank" rel="noreferrer">Space Cowboy.</a></small>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
