import React from 'react'
import { Link } from 'gatsby';
import './logo.scss';

const Logo = props => {
  return (
    <h4><Link to="/" className='sds-link-hover--3 internal'><span className="sds-logo">Skralan</span></Link></h4 >
  )
}

Logo.propTypes = {}

export default Logo
